import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { ErrorScreen } from "components/notifications";
import Landing from "components/Landing";
import CharacterPicker from "components/CharacterPicker";
import { NOT_FOUND_404 } from "constants/index";

const Routes = ({ config }) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Landing config={config} />} />
        <Route
          exact
          path="/pick"
          render={() => <CharacterPicker config={config} />}
        />
        {/* <Route path={ "/url" + ":uid/:token"} render={() => (<></>)} /> */}
        <Route
          path={NOT_FOUND_404}
          component={() => (
            <ErrorScreen error={{ code: 404, errorMsg: "Page not found." }} />
          )}
        />
        <Redirect to={NOT_FOUND_404} />
      </Switch>
    </Router>
  );
};

export default Routes;
