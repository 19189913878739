import * as SystemActions from "../actions";

const systemInitState = {
  isConfigReady: false,
  mainError: {
    code: null,
    errorMsg: null,
  },
  height: null,
  width: null,
  landscape: false,
  config: null,
};

function systemReducer(state = systemInitState, action) {
  switch (action.type) {
    case SystemActions.SYSTEM_CONFIG_LOADED:
      return {
        ...state,
        isConfigReady: true,
        config: action.payload.config,
      };
    case SystemActions.SYSTEM_CONFIG_FAILED:
      return {
        ...state,
        isConfigReady: true,
        mainError: {
          code: 400,
          errorMsg: "Configuration Error",
        },
      };
    case SystemActions.SYSTEM_ERROR:
      return {
        ...state,
        mainError: action.payload,
      };
    case SystemActions.RESET_SYSTEM_ERROR:
      return {
        ...state,
        mainError: {
          code: null,
          errorMsg: null,
        },
      };
    case SystemActions.UPDATE_DEVICE_INFO:
      return {
        ...state,
        height: action.payload.height,
        width: action.payload.width,
        landscape: action.payload.width > action.payload.height ? true : false,
      };
    default:
      return {
        ...state,
      };
  }
}

export { systemReducer };
