import BigNumber from "bignumber.js";
import scoring from "./scoring.json";
import attributes from "./attributes.json";

// Fine Tunning
// Sample acceptance
const nAcceptedZeroTraits = 0;
const nAcceptedZeroStudios = 2;
const minIterations = 4;
const softMaxIterations = 6;
// const hardMaxIterations = 12;

// Analisys
const topStudiosToRemove = 2;

class SmartPicker {
  constructor() {
    this.initialized = false;
    this.studios = attributes.studios;
    this.traits = attributes.traits;
    this.currentOptions = [];
    this.characterPool = [];
    this.selectedCharacters = [];
    this.scoreCounter = {};
    this.recommendedCharacters = [];
    this.iterations = 0;
    this.distanceMatrix = {};

    this.error = { status: false, events: [] };
    this.history = { events: [] };
    this.init();
  }

  init() {
    if (
      !scoring ||
      !this.traits ||
      !this.studios ||
      !Array.isArray(scoring) ||
      !Array.isArray(this.traits) ||
      !Array.isArray(this.studios)
    ) {
      console.log("SmartPicker: Missing data.");
      return;
    }
    this.initScoreCounter();
    this.characterPool = scoring.map((char, idx) => {
      return { ...char, id: idx };
    });
    this.initialized = true;
  }

  initScoreCounter() {
    const columns = [...this.traits, ...this.studios];
    this.scoreCounter = columns.reduce((obj, col) => {
      obj[col] = 0;
      return obj;
    }, {});
  }

  reset() {}

  randomPickUnique(arr, nPicks) {
    const ar = [...arr];
    const picks = [];
    let remPicks = nPicks && nPicks > 0 ? nPicks : 1;

    while (remPicks) {
      const pickIdx = Math.floor(Math.random() * ar.length);
      picks.push(ar[pickIdx]);
      ar.splice(pickIdx, 1);
      remPicks -= 1;
    }
    return picks;
  }

  pickOptions(arr, nPicks) {
    const guidedPick = !!(this.iterations > softMaxIterations);

    if (guidedPick) {
      let newPool = [];
      const targetStudios = [];
      this.studios.forEach((studio) => {
        if (this.scoreCounter[studio] === 0) {
          targetStudios.push(studio);
        }
      });
      if (targetStudios.length > nAcceptedZeroStudios) {
        arr.forEach((char) => {
          targetStudios.forEach((studio) => {
            if (char[studio] === 1) {
              newPool.push(char);
            }
          });
        });
      }
      console.log(targetStudios, { newPool, arr });
      newPool = newPool.length >= 2 ? newPool : arr;
      return this.randomPickUnique(newPool, nPicks);
    } else {
      return this.randomPickUnique(arr, nPicks);
    }
  }

  removeCharsFromPool(charsToRemove, charPool) {
    let pool = [...charPool];
    if (
      !charsToRemove ||
      !Array.isArray(charsToRemove) ||
      !charPool ||
      !Array.isArray(charPool)
    ) {
      console.log({ charsToRemove }, "where not removed from", { charPool });
      return pool;
    }
    charsToRemove.forEach((charToRemove) => {
      const idx = pool.findIndex((char) => char.id === charToRemove.id);
      if (idx >= 0) {
        pool.splice(idx, 1);
      }
    });
    return pool;
  }

  findDistance(a, b) {
    const ax = new BigNumber(a[0]);
    const ay = new BigNumber(a[1]);
    const bx = new BigNumber(b[0]);
    const by = new BigNumber(b[1]);
    const x = ax.minus(bx).exponentiatedBy(2);
    const y = ay.minus(by).exponentiatedBy(2);
    return x.plus(y).squareRoot();
  }

  findCharacterStudio(char) {
    return this.studios.filter((studio) => char[studio] === 1)[0];
  }

  generateDistanceMatrix(selected, pool) {
    const dMatrix = selected.reduce((matrix, sChar, idx) => {
      const { PC0: ax, PC1: ay } = sChar;
      const aPoint = [ax, ay];
      const dfRow = pool.reduce((row, unChar) => {
        const { PC0: bx, PC1: by } = unChar;
        const bPoint = [bx, by];
        row.push(this.findDistance(aPoint, bPoint));
        return row;
      }, []);
      matrix[idx] = dfRow;
      return matrix;
    }, []);
    return dMatrix;
  }

  filterCharacterPool(pool) {
    const chars = [...pool];
    const finalPool = [];
    const sortedStudios = this.studios
      .map((s) => {
        return {
          name: s,
          count: this.scoreCounter[s],
        };
      })
      .sort((a, b) => a.count - b.count);

    const studiosToRemove = sortedStudios
      .splice(sortedStudios.length - topStudiosToRemove, topStudiosToRemove)
      .map((s) => s.name);
    const allowedStudios = sortedStudios.map((s) => s.name);

    console.log(studiosToRemove);

    chars.forEach((char) => {
      allowedStudios.forEach((studio) => {
        if (char[studio] === 1) {
          finalPool.push(char);
        }
      });
    });
    return finalPool;
  }

  filterSelectedByTrait(selectedChars, trait) {
    const chars = [...selectedChars];
    const targetScore = this.scoreCounter[trait] >= 0 ? 3 : 1;
    return chars.filter((char) => char[trait] === targetScore);
  }

  findMinDistanceIndex(dMatrix) {
    let characterIdx = null;
    let min = null;
    // Transpose matrix
    const transDMatrix = dMatrix[0].map((_, colIndex) =>
      dMatrix.map((row) => row[colIndex])
    );

    transDMatrix.forEach((unChar, idx) => {
      const sorted = unChar.sort((a, b) => {
        return a.minus(b);
      }); //.map(d => d.toNumber());
      // First iteration
      min = min === null ? sorted[0] : min;
      characterIdx = characterIdx === null ? idx : characterIdx;
      if (sorted[0].lt(min)) {
        min = sorted[0];
        characterIdx = idx;
      }
    });
    console.log({ min: min.toNumber(), characterIdx });
    return characterIdx;
  }

  processSelection(winner, loser) {
    this.selectedCharacters.push(winner);
    this.iterations += 1;
    const currentScore = { ...this.scoreCounter };

    const traitCount = [...this.traits].reduce((obj, col) => {
      obj[col] = winner[col] - loser[col] + currentScore[col];
      return obj;
    }, {});
    const studioCount = [...this.studios].reduce((obj, col) => {
      obj[col] = winner[col] + currentScore[col];
      return obj;
    }, {});
    const updatedScore = { ...traitCount, ...studioCount };
    this.scoreCounter = updatedScore;
    this.history.events.push({
      iteration: this.iterations,
      winner,
      loser,
      score: updatedScore,
    });
  }

  // Class interface methods
  isReadyToAnalyze() {
    if (!this.initialized) {
      console.log("SmartPicker: Not initialized.");
      return false;
    }

    const nZeroTraits = this.traits.reduce(
      (nZeroTraits, trait) =>
        this.scoreCounter[trait] === 0 ? (nZeroTraits += 1) : nZeroTraits,
      0
    );
    const traitsCheck = !!(nZeroTraits <= nAcceptedZeroTraits);

    const nZeroStudios = this.studios.reduce(
      (nZeroStudios, studio) =>
        this.scoreCounter[studio] === 0 ? (nZeroStudios += 1) : nZeroStudios,
      0
    );
    const studiosCheck = !!(nZeroStudios <= nAcceptedZeroStudios);

    const iterationsCheck = !!(this.iterations >= minIterations);
    return !!(iterationsCheck && traitsCheck && studiosCheck);
  }

  getOptions() {
    if (
      !Array.isArray(this.currentOptions) ||
      this.currentOptions.length > 0 ||
      this.isReadyToAnalyze()
    ) {
      console.log({
        options: this.currentOptions,
        score: this.scoreCounter,
        init: this.initialized,
      });
      console.log("submitChoice before requesting more options.");
      return;
    }
    // this.currentOptions = this.randomPickUnique(this.characterPool, 2);
    this.currentOptions = this.pickOptions(this.characterPool, 2);
    const updatedPool = this.removeCharsFromPool(
      this.currentOptions,
      this.characterPool
    );
    this.characterPool = updatedPool;

    return [...this.currentOptions];
  }

  submitChoice(id) {
    return new Promise((resolve, reject) => {
      if (
        !id ||
        !Array.isArray(this.currentOptions) ||
        this.currentOptions.length === 0
      )
        return reject();
      const options = [...this.currentOptions];
      this.currentOptions = [];
      const selectedIdx = options.findIndex((c) => c.id === id);
      const winner = options.splice(selectedIdx, 1)[0];
      const loser = options[0];
      this.processSelection(winner, loser);
      return resolve();
    });
  }

  analyze() {
    if (!this.isReadyToAnalyze()) return;
    if (this.recommendedCharacters.length > 0) return;
    Object.freeze(this.selectedCharacters);
    Object.freeze(this.characterPool);
    Object.freeze(this.scoreCounter);
    this.history.finalScore = { ...this.scoreCounter };

    let remCharPool = this.filterCharacterPool(this.characterPool);
    this.traits.forEach((trait) => {
      const selectedWithTrait = this.filterSelectedByTrait(
        this.selectedCharacters,
        trait
      );
      const dMatrix = this.generateDistanceMatrix(
        selectedWithTrait,
        remCharPool
      );
      if (!dMatrix || dMatrix.length === 0) {
        this.error.events.push({
          msg: "Cannot generate dMatrix.",
          trait,
          score: this.scoreCounter,
          selectedCharacters: this.selectedCharacters,
          selectedWithTrait,
          remCharPool,
        });
        this.error.status = true;
        return [];
      }
      const idx = this.findMinDistanceIndex(dMatrix);
      const recommendedChar = remCharPool.splice(idx, 1)[0];
      const studio = this.findCharacterStudio(recommendedChar);
      // console.log(remCharPool);
      this.recommendedCharacters.push({
        studio,
        trait,
        character: recommendedChar,
      });
    });
    this.history.recommendedCharacters = [...this.recommendedCharacters];
    console.log(this.history);
    if (this.error.status) {
      console.log("Errors:", this.error.events);
    }
    return this.recommendedCharacters;
  }
}

export default SmartPicker;
