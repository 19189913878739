import React from "react";
import css from "./landing.module.scss";
import { ReactComponent as Logo } from "static/disney-plus-logo.svg";

const block = "landing";

const Landing = ({ config }) => {
  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__main`]}>
          <Logo className={css[`${block}__main__image`]} />
        </div>
        <button className={css[`${block}__begin-btn`]}>Comenzar</button>
        <div className={css[`${block}__footer`]}>
          <Logo className={css[`${block}__footer__logo`]} />
        </div>
      </div>
    </>
  );
};
export default Landing;
