import React, { useEffect, useState } from "react";
import css from "./characterPicker.module.scss";
import { ReactComponent as Logo } from "static/disney-plus-logo.svg";
import SmartPicker from "./SmartPicker";

const block = "picker";
let sp = new SmartPicker();

const CharacterPicker = ({ config }) => {
  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    const options = sp.getOptions();
    setCharacters(options);
  }, []);

  const onCharacterSelection = (id) => {
    sp.submitChoice(id)
      .then(() => {
        if (!sp.isReadyToAnalyze()) {
          const options = sp.getOptions();
          setCharacters(options);
        } else {
          console.log("analizing...");
          const result = sp.analyze();
          console.log(result);
        }
      })
      .catch((e) => {
        //
      });
  };

  const renderCharacterChoices = (chars) => {
    return chars.map((c) => {
      return (
        <button
          key={c.id}
          className={css[`${block}__main__character`]}
          onClick={() => onCharacterSelection(c.id)}
        >
          {c.Character}
        </button>
      );
    });
  };

  return (
    <>
      <div className={css[`${block}`]}>
        <div className={css[`${block}__main`]}>
          {characters &&
            characters.length > 0 &&
            renderCharacterChoices(characters)}
        </div>
        <div className={css[`${block}__footer`]}>
          <Logo className={css[`${block}__footer__logo`]} />
        </div>
      </div>
    </>
  );
};
export default CharacterPicker;
