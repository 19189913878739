async function loadFile(path) {
  let error = false;
  let response;

  try {
    response = await fetch(path);
  } catch (e) {
    console.log(e);
    error = true;
  }
  const data = await response.json().catch(() => {
    console.log(path, " content is not valid JSON.");
    error = true;
  });
  const result = error === false ? data : null;
  return result;
}

function debounce(fn, interval, { leading } = {}) {
  let timeout;
  let leadExecuted = false;
  const timer = typeof interval === "number" ? interval : 200;
  const lead = typeof leading === "boolean" ? leading : false;
  return (...args) => {
    const context = this;
    const postponed = () => {
      timeout = null;
      if (lead) {
        leadExecuted = false;
      } else {
        fn.apply(context, args);
      }
    };
    clearTimeout(timeout);
    timeout = setTimeout(postponed, timer);
    if (lead && !leadExecuted) {
      leadExecuted = true;
      fn.apply(context, args);
    }
  };
}

// function disableScroll() {
//   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//   const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
//   window.onscroll = function () {
//     window.scrollTo(scrollLeft, scrollTop);
//   };
// };

// function enableScroll() {
//   window.onscroll = function () { };
// };

const disableScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = "fixed";
  // TODO: Browser / device detection.
  // Safari Mobile works better this way:
  // document.body.style.position = "absolute";
  // document.body.style.overflow = "hidden";
};

const enableScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = "";
  // TODO: Browser / device detection.
  document.body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
  // Safari Mobile works better this way:
  // document.body.style.overflow = "";
};

export { loadFile, debounce, enableScroll, disableScroll };
