import React, { Component } from "react";
import { connect } from "react-redux";
import * as GlobalActions from "redux/system/actions";
import { loadFile, debounce } from "utils";
import { getConfigFile } from "constants/index";
import { ErrorScreen } from "components/notifications";
import Routes from "routes";
import "global.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateScreenSize = debounce(this.updateScreenSize.bind(this), 150);
  }

  componentDidMount() {
    const { initApp } = this.props;
    initApp(getConfigFile());
    this.updateScreenSize();

    // Attach listeners
    window.addEventListener("resize", this.updateScreenSize, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize, false);
  }

  updateScreenSize() {
    const { height, width, updateDeviceInfo } = this.props;
    let newState = { updated: false };

    const newHeight = document.documentElement.clientHeight;
    const newWidth = document.documentElement.clientWidth;

    if (newWidth !== width || newHeight !== height) {
      newState["height"] = newHeight;
      newState["width"] = newWidth;
      newState["updated"] = true;
    }

    if (newState.updated) {
      delete newState.updated;
      updateDeviceInfo(newState);
    }
  }

  render() {
    const { config, mainError } = this.props;
    let appRender;

    if (mainError.code) {
      appRender = <ErrorScreen error={mainError} />;
    } else {
      if (!config) {
        appRender = <div>Loading...</div>;
      } else {
        appRender = Routes({ config });
      }
    }
    return <div className="app">{appRender}</div>;
  }
}

function mapStateToProps(state) {
  const {
    isConfigReady,
    mainError,
    landscape,
    height,
    width,
    config,
  } = state.systemReducer;
  return {
    isConfigReady,
    config,
    mainError,
    landscape,
    height,
    width,
  };
}
const mapDispatchToProps = (dispatch, ownProps, state) => {
  return {
    // This is the first method to be executed, if it fails it displays an error.
    initApp: async function (configFile) {
      const config = await loadFile(configFile);
      if (config) {
        dispatch({
          type: GlobalActions.SYSTEM_CONFIG_LOADED,
          payload: { config },
        });
      } else {
        console.log(`Could not load config: ${configFile}`);
        dispatch({ type: GlobalActions.SYSTEM_CONFIG_FAILED });
      }
    },

    updateDeviceInfo: (info) => {
      dispatch({ type: GlobalActions.UPDATE_DEVICE_INFO, payload: info });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
